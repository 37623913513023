import { message } from "antd";
import axios, { AxiosError, AxiosResponse } from "axios";
import { Helpers } from "../helpers";
import { Redux_Actions, store } from "../store";

export const Base_Url = Helpers.isProduction
  ? process.env.REACT_APP_PRODUCTION_API_URL
  : process.env.REACT_APP_SIMULATE_PRODUCTION === "true"
  ? process.env.REACT_APP_PRODUCTION_API_URL
  : process.env.REACT_APP_LOCAL_API_URL;

const axiosInstance = axios.create({
  baseURL: Base_Url,
  timeout: 600000,
  withCredentials: false,
});
axiosInstance.interceptors.request.use(
  async (config) => {
    config.headers.Authorization = `Bearer ${store.getState().token}`;
    return config;
  },
  (error) => Promise.reject(error)
);

const defaultApiRoutes: (basePath: string) => {
  create: (args: any) => Promise<AxiosResponse>;
  table: (args: any) => Promise<AxiosResponse>;
  single: (args: any) => Promise<AxiosResponse>;
  update: (args: any) => Promise<AxiosResponse>;
  delete: (args: any) => Promise<AxiosResponse>;
  restore: (args: any) => Promise<AxiosResponse>;
  statistics: () => Promise<AxiosResponse>;
  list: () => Promise<AxiosResponse>;
} = (basePath: string) => {
  return {
    create: ({ ...args }: { args: any }): Promise<AxiosResponse> =>
      axiosInstance.post(`/${basePath}/create`, args),
    table: (args: any): Promise<AxiosResponse> =>
      axiosInstance.get(`/${basePath}/table`, { params: args }),
    single: (id: any): Promise<AxiosResponse> => axiosInstance.get(`/${basePath}/single/${id}`),
    update: ({ id, ...args }: { id: any; args: any }): Promise<AxiosResponse> =>
      axiosInstance.put(`/${basePath}/update/${id}`, { ...args }),
    list: (): Promise<AxiosResponse> => axiosInstance.get(`/${basePath}/list`),
    delete: (id: any): Promise<AxiosResponse> => axiosInstance.delete(`/${basePath}/delete/${id}`),
    restore: (id: any): Promise<AxiosResponse> => axiosInstance.post(`/${basePath}/restore/${id}`),
    statistics: (): Promise<AxiosResponse> => axiosInstance.get(`/${basePath}/statistics`),
  };
};

axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error: AxiosError) => {
    // message.error()
    if (error.response) {
      if (error.response.status === 401) {
        message.error("You have been logged out from the server");
        store.dispatch({ type: Redux_Actions.logout });
      }
      if (error.response.data) {
        let err = error.response.data.message;
        if (err) {
          if (Array.isArray(err) && error.response.status === 400) {
            err.forEach((i) => {
              message.error(i.msg);
            });
          } else if (err) {
            message.error(err);
          }
        } else message.error(error.toString());
      } else {
        message.error(error.toString());
      }
    } else {
      message.error(error.toString());
    }

    return Promise.reject(error);
  }
);

export let Api = {
  upload: ({ formData }: any): Promise<AxiosResponse> =>
    axiosInstance.post("/file/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  login: ({ username, password }: { username: string; password: string }): Promise<any> =>
    axiosInstance.post("/login", {
      email: username,
      password,
    }),
  logout: (): Promise<AxiosResponse> => axiosInstance.get("/logout"),
  users: {
    create: ({ ...args }: { args: any }): Promise<AxiosResponse> =>
      axiosInstance.post("/users/create", args),
    table: (args: any): Promise<AxiosResponse> =>
      axiosInstance.get("/users/table", { params: args }),
    single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/users/single/${id}`),
    update: ({ id, ...args }: { id: number; args: any }): Promise<AxiosResponse> =>
      axiosInstance.put(`/users/update/${id}`, { ...args }),
    delete: (id: number): Promise<AxiosResponse> => axiosInstance.delete(`/users/delete/${id}`),
    restore: (id: number): Promise<AxiosResponse> => axiosInstance.post(`/users/restore/${id}`),
    resetPassword: ({ id, args }: { id: number; args: any }): Promise<AxiosResponse> =>
      axiosInstance.put(`/users/reset/${id}`, { ...args }),
  },
  categories: {
    create: (args: any): Promise<AxiosResponse> => axiosInstance.post("/categories/create", args),
    table: (args: any): Promise<AxiosResponse> =>
      axiosInstance.get("/categories/table", { params: args }),
    statistics: (): Promise<AxiosResponse> => axiosInstance.get("/categories/statistics"),
    list: (): Promise<AxiosResponse> => axiosInstance.get("/categories/list"),
    single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/categories/single/${id}`),
    update: ({ id, ...args }: { id: number; args: any }): Promise<AxiosResponse> =>
      axiosInstance.put(`/categories/update/${id}`, {
        ...args,
      }),
    delete: (id: number): Promise<AxiosResponse> =>
      axiosInstance.delete(`/categories/delete/${id}`),
    restore: (id: number): Promise<AxiosResponse> =>
      axiosInstance.post(`/categories/restore/${id}`),
  },
  collections: {
    create: ({ ...args }: { args: any }): Promise<AxiosResponse> =>
      axiosInstance.post("/collections/create", args),
    table: (args: any): Promise<AxiosResponse> =>
      axiosInstance.get("/collections/table", { params: args }),
    statistics: (): Promise<AxiosResponse> => axiosInstance.get("/collections/statistics"),
    list: (): Promise<AxiosResponse> => axiosInstance.get("/collections/list"),
    single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/collections/single/${id}`),
    update: ({ id, ...args }: { id: number; args: any }): Promise<AxiosResponse> =>
      axiosInstance.put(`/collections/update/${id}`, {
        ...args,
      }),
    delete: (id: number): Promise<AxiosResponse> =>
      axiosInstance.delete(`/collections/delete/${id}`),
    restore: (id: number): Promise<AxiosResponse> =>
      axiosInstance.post(`/collections/restore/${id}`),
  },
  promoCodes: {
    create: ({ ...args }: { id: number; args: any }): Promise<AxiosResponse> =>
      axiosInstance.post(`/promoCodes/create`, args),
    table: (args: any): Promise<AxiosResponse> =>
      axiosInstance.get("/promoCodes/table", { params: args }),
    single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/promoCodes/single/${id}`),
    update: ({ id, ...args }: { id: number; args: any }): Promise<AxiosResponse> =>
      axiosInstance.put(`/promoCodes/update/${id}`, {
        ...args,
      }),
    statistics: (): Promise<AxiosResponse> => axiosInstance.get("/promoCodes/statistics"),
    delete: (id: number): Promise<AxiosResponse> =>
      axiosInstance.delete(`/promoCodes/delete/${id}`),
    restore: (id: number): Promise<AxiosResponse> =>
      axiosInstance.post(`/promoCodes/restore/${id}`),
  },
  priceRequest: {
    create: ({ ...args }: { id: number; args: any }): Promise<AxiosResponse> =>
      axiosInstance.post(`/price-request/create`, args),
    table: (args: any): Promise<AxiosResponse> =>
      axiosInstance.get("/price-request/table", { params: args }),
    single: (id: number): Promise<AxiosResponse> =>
      axiosInstance.get(`/price-request/single/${id}`),
    update: ({ id, ...args }: { id: number; args: any }): Promise<AxiosResponse> =>
      axiosInstance.put(`/price-request/update/${id}`, {
        ...args,
      }),
    delete: (id: number): Promise<AxiosResponse> =>
      axiosInstance.delete(`/price-request/delete/${id}`),
    restore: (id: number): Promise<AxiosResponse> =>
      axiosInstance.post(`/price-request/restore/${id}`),
  },
  materials: {
    create: ({ ...args }: { args: any }): Promise<AxiosResponse> =>
      axiosInstance.post("/materials/create", args),
    table: (args: any): Promise<AxiosResponse> =>
      axiosInstance.get("/materials/table", { params: args }),
    statistics: (): Promise<AxiosResponse> => axiosInstance.get("/materials/statistics"),
    list: (): Promise<AxiosResponse> => axiosInstance.get("/materials/list"),
    single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/materials/single/${id}`),
    update: ({ id, ...args }: { id: number; args: any }): Promise<AxiosResponse> =>
      axiosInstance.put(`/materials/update/${id}`, {
        ...args,
      }),
    delete: (id: number): Promise<AxiosResponse> => axiosInstance.delete(`/materials/delete/${id}`),
    restore: (id: number): Promise<AxiosResponse> => axiosInstance.post(`/materials/restore/${id}`),
  },
  tags: {
    create: ({ ...args }: any): Promise<AxiosResponse> => axiosInstance.post("/tags/create", args),
    table: (args: any): Promise<AxiosResponse> =>
      axiosInstance.get("/tags/table", { params: args }),
    statistics: (): Promise<AxiosResponse> => axiosInstance.get("/tags/statistics"),
    list: (): Promise<AxiosResponse> => axiosInstance.get("/tags/list"),
    single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/tags/single/${id}`),
    update: ({ id, ...args }: { id: number; args: any }): Promise<AxiosResponse> =>
      axiosInstance.put(`/tags/update/${id}`, {
        ...args,
      }),
    delete: (id: number): Promise<AxiosResponse> => axiosInstance.delete(`/tags/delete/${id}`),
    restore: (id: number): Promise<AxiosResponse> => axiosInstance.post(`/tags/restore/${id}`),
  },
  pages: {
    create: ({ ...args }: any): Promise<AxiosResponse> => axiosInstance.post("/pages/create", args),
    table: (args: any): Promise<AxiosResponse> =>
      axiosInstance.get("/pages/table", { params: args }),
    statistics: (): Promise<AxiosResponse> => axiosInstance.get("/pages/statistics"),
    list: (): Promise<AxiosResponse> => axiosInstance.get("/pages/list"),
    single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/pages/single/${id}`),
    update: ({ id, ...args }: { id: number; args: any }): Promise<AxiosResponse> =>
      axiosInstance.put(`/pages/update/${id}`, {
        ...args,
      }),
  },
  itemOption: {
    create: ({ ...args }: any): Promise<AxiosResponse> =>
      axiosInstance.post("/itemOption/create", args),
    table: (args: any): Promise<AxiosResponse> =>
      axiosInstance.get("/itemOption/table", { params: args }),
    statistics: (): Promise<AxiosResponse> => axiosInstance.get("/itemOption/statistics"),
    list: (): Promise<AxiosResponse> => axiosInstance.get("/itemOption/list"),
    single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/itemOption/single/${id}`),
    update: ({ id, ...args }: { id: number; args: any }): Promise<AxiosResponse> =>
      axiosInstance.put(`/itemOption/update/${id}`, {
        ...args,
      }),
    delete: (id: number): Promise<AxiosResponse> =>
      axiosInstance.delete(`/itemOption/delete/${id}`),
    restore: (id: number): Promise<AxiosResponse> =>
      axiosInstance.post(`/itemOption/restore/${id}`),
  },
  items: {
    create: ({ ...args }: any): Promise<AxiosResponse> => axiosInstance.post("/items/create", args),
    table: (args: any): Promise<AxiosResponse> =>
      axiosInstance.get("/items/table", { params: args }),
    statistics: (): Promise<AxiosResponse> => axiosInstance.get("/items/statistics"),
    list: (): Promise<AxiosResponse> => axiosInstance.get("/items/list"),
    single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/items/single/${id}`),
    update: ({ id, ...args }: { id: number; args: any }): Promise<AxiosResponse> =>
      axiosInstance.put(`/items/update/${id}`, {
        ...args,
      }),
    delete: (id: number): Promise<AxiosResponse> => axiosInstance.delete(`/items/delete/${id}`),
    restore: (id: number): Promise<AxiosResponse> => axiosInstance.post(`/items/restore/${id}`),
    singleWithVariations: (id: number): Promise<AxiosResponse> =>
      axiosInstance.get(`/item/variations`, { params: { itemId: id } }),
  },
  itemRequests: {
    create: ({ ...args }: any): Promise<AxiosResponse> =>
      axiosInstance.post("/request/create", args),
    table: (args: any): Promise<AxiosResponse> =>
      axiosInstance.get("/request/table", { params: args }),
    statistics: (): Promise<AxiosResponse> => axiosInstance.get("/request/statistics"),
    list: (): Promise<AxiosResponse> => axiosInstance.get("/request/list"),
    single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/request/single/${id}`),
    update: ({ id, ...args }: { id: number; args: any }): Promise<AxiosResponse> =>
      axiosInstance.put(`/request/update/${id}`, {
        ...args,
      }),
    delete: (id: number): Promise<AxiosResponse> => axiosInstance.delete(`/request/delete/${id}`),
    restore: (id: number): Promise<AxiosResponse> => axiosInstance.post(`/request/restore/${id}`),
  },
  contactForms: {
    create: ({ ...args }: any): Promise<AxiosResponse> =>
      axiosInstance.post("/contact-form/create", args),
    table: (args: any): Promise<AxiosResponse> =>
      axiosInstance.get("/contact-form/table", { params: args }),
    statistics: (): Promise<AxiosResponse> => axiosInstance.get("/contact-form/statistics"),
    list: (): Promise<AxiosResponse> => axiosInstance.get("/contact-form/list"),
    single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/contact-form/single/${id}`),
    update: ({ id, ...args }: { id: number; args: any }): Promise<AxiosResponse> =>
      axiosInstance.put(`/contact-form/update/${id}`, {
        ...args,
      }),
    delete: (id: number): Promise<AxiosResponse> =>
      axiosInstance.delete(`/contact-form/delete/${id}`),
    restore: (id: number): Promise<AxiosResponse> =>
      axiosInstance.post(`/contact-form/restore/${id}`),
  },
  itemVariation: {
    create: ({ ...args }: any): Promise<AxiosResponse> =>
      axiosInstance.post("/itemVariation/create", args),
    table: (args: any): Promise<AxiosResponse> =>
      axiosInstance.get("/itemVariation/table", { params: args }),
    statistics: (): Promise<AxiosResponse> => axiosInstance.get("/itemVariation/statistics"),
    list: (): Promise<AxiosResponse> => axiosInstance.get("/itemVariation/list"),
    single: (id: number): Promise<AxiosResponse> =>
      axiosInstance.get(`/itemVariation/single/${id}`),
    update: ({ id, ...args }: { id: number; args: any }): Promise<AxiosResponse> =>
      axiosInstance.put(`/itemVariation/update/${id}`, {
        ...args,
      }),
    delete: (id: number): Promise<AxiosResponse> =>
      axiosInstance.delete(`/itemVariation/delete/${id}`),
    restore: (id: number): Promise<AxiosResponse> =>
      axiosInstance.post(`/itemVariation/restore/${id}`),
  },
  emailSubscriptions: {
    create: ({ ...args }: any): Promise<AxiosResponse> =>
      axiosInstance.post("/emailSubscriptions/create", args),
    table: (args: any): Promise<AxiosResponse> =>
      axiosInstance.get("/emailSubscriptions/table", { params: args }),
    statistics: (): Promise<AxiosResponse> => axiosInstance.get("/emailSubscriptions/statistics"),
    list: (args?: any): Promise<AxiosResponse> =>
      axiosInstance.get("/emailSubscriptions/list", { params: args }),
    single: (id: number): Promise<AxiosResponse> =>
      axiosInstance.get(`/emailSubscriptions/single/${id}`),
    update: ({ id, ...args }: { id: number; args: any }): Promise<AxiosResponse> =>
      axiosInstance.put(`/emailSubscriptions/update/${id}`, {
        ...args,
      }),
    delete: (id: number): Promise<AxiosResponse> =>
      axiosInstance.delete(`/emailSubscriptions/delete/${id}`),
    restore: (id: number): Promise<AxiosResponse> =>
      axiosInstance.post(`/emailSubscriptions/restore/${id}`),
  },
  orders: {
    create: ({ ...args }: any): Promise<AxiosResponse> =>
      axiosInstance.post("/orders/create", args),
    table: (args: any): Promise<AxiosResponse> =>
      axiosInstance.get("/orders/table", { params: args }),
    statistics: (): Promise<AxiosResponse> => axiosInstance.get("/orders/statistics"),
    list: (): Promise<AxiosResponse> => axiosInstance.get("/orders/list"),
    single: (id: string): Promise<AxiosResponse> => axiosInstance.get(`/orders/single/${id}`),
    update: ({ id, ...args }: { id: number; args: any }): Promise<AxiosResponse> =>
      axiosInstance.put(`/orders/update/${id}`, {
        ...args,
      }),
    cancel: (id: number): Promise<AxiosResponse> => axiosInstance.post(`/orders/cancel/${id}`),
    setStatus: (id: number, status: string): Promise<AxiosResponse> =>
      axiosInstance.post(`/orders/setStatus`, { id, status }),
    delete: (id: number): Promise<AxiosResponse> => axiosInstance.delete(`/orders/delete/${id}`),
    restore: (id: number): Promise<AxiosResponse> => axiosInstance.post(`/orders/restore/${id}`),
  },
  brands: {
    create: ({ ...args }: any): Promise<AxiosResponse> =>
      axiosInstance.post("/brands/create", args),
    table: (args: any): Promise<AxiosResponse> =>
      axiosInstance.get("/brands/table", { params: args }),
    statistics: (): Promise<AxiosResponse> => axiosInstance.get("/brands/statistics"),
    list: (): Promise<AxiosResponse> => axiosInstance.get("/brands/list"),
    single: (id: number): Promise<AxiosResponse> => axiosInstance.get(`/brands/single/${id}`),
    update: ({ id, ...args }: { id: number; args: any }): Promise<AxiosResponse> =>
      axiosInstance.put(`/brands/update/${id}`, {
        ...args,
      }),
    delete: (id: number): Promise<AxiosResponse> => axiosInstance.delete(`/brands/delete/${id}`),
    restore: (id: number): Promise<AxiosResponse> => axiosInstance.post(`/brands/restore/${id}`),
  },
  blogs: defaultApiRoutes("blogs"),
  profile: {
    myNotifications: (): Promise<AxiosResponse> => axiosInstance.get("/profile/notifications"),
    clearMyNotifications: (): Promise<AxiosResponse> =>
      axiosInstance.post("/profile/clearNotifications"),
  },
};
