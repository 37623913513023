import {
  BellOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ProfileOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Badge, Col, Dropdown, Layout, Menu, message, Row, Space } from "antd";
import React, { useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useTrackedState } from "reactive-react-redux";
import { Routes } from "../routes";
import { Redux_Actions, State } from "../store";
import { Breadcrumbs } from "./Breadcrumbs";
import "./layout.css";
import { ModuleHeader } from "./ModuleHeader";
import NotificationsHeader from "./Notifications/NotificationsHeader";

const { Header, Sider, Content } = Layout;
export const AppLayout = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const state = useTrackedState<State>();
  const history = useHistory();
  const location = useLocation();
  const toggle = () => {
    setIsCollapsed(!isCollapsed);
  };
  return (
    <Layout>
      <Sider
        theme="dark"
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
        }}
        trigger={null}
        collapsible
        collapsed={isCollapsed}
      >
        <img
          alt={"Logo"}
          src={"/logo-white.png"}
          style={{
            width: "100%",
            height: "150px",
            paddingBottom: 15,
            padding: 10,
            objectFit: "contain",
          }}
        />
        <Menu theme="dark" mode="inline" selectedKeys={[location.pathname]}>
          {Routes.filter((i: any) => i.hideFromMenu !== true).map((route: any) => {
            if (route.routes)
              return (
                <Menu.SubMenu
                  key={route.path}
                  title={
                    <span>
                      {React.createElement(route.icon)}
                      <span>{route.title}</span>
                    </span>
                  }
                >
                  <Menu.Item key={route.path}>
                    {route.icon && React.createElement(route.icon)}
                    <span>{route.title}</span>
                    <Link to={route.path} />
                  </Menu.Item>

                  {route.routes
                    .filter((i: any) => i.hideFromMenu !== true)
                    .map((child: any) => (
                      <Menu.Item key={child.path}>
                        {child.icon && React.createElement(child.icon)}
                        <span>{child.title}</span>
                        <Link to={child.path} />
                      </Menu.Item>
                    ))}
                </Menu.SubMenu>
              );
            return (
              <Menu.Item key={route.path}>
                {React.createElement(route.icon)}
                <span>{route.title}</span>
                <Link to={route.path} />
              </Menu.Item>
            );
          })}
        </Menu>
      </Sider>
      <Layout className="site-layout" style={{ marginLeft: isCollapsed ? 80 : 200 }}>
        <Header style={{ padding: 0, color: "white" }}>
          <Row>
            <Col span={2}>
              {React.createElement(isCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: "trigger",
                onClick: toggle,
              })}
            </Col>
            <Col style={{ cursor: "pointer" }} span={1} offset={17}>
              <NotificationsHeader />
            </Col>
            <Col style={{ cursor: "pointer" }} span={4}>
              <Dropdown
                overlay={
                  <Menu theme="dark">
                    <Menu.Item>
                      <span
                        onClick={() => {
                          const hide = message.loading("logging out...");
                          dispatch({ type: Redux_Actions.logout });
                          hide();
                        }}
                      >
                        <LogoutOutlined /> Logout
                      </span>
                    </Menu.Item>
                    <Menu.Item>
                      <span>
                        <SettingOutlined /> Settings
                      </span>
                    </Menu.Item>
                    <Menu.Item>
                      <span>
                        <ProfileOutlined /> Profile
                      </span>
                    </Menu.Item>
                  </Menu>
                }
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Space>
                    <Avatar style={{ backgroundColor: "#87d068" }} icon={<UserOutlined />} />
                    {state.user && (
                      <span>
                        {state.user.firstName} {state.user.lastName}
                      </span>
                    )}
                  </Space>
                </div>
              </Dropdown>
            </Col>
          </Row>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: "0 24px 24px 0 ",
            padding: "0 24px 24px 24px",
            minHeight: "80vh",
          }}
        >
          <Space style={{ width: "100%" }} direction={"vertical"} size="middle">
            {/* <Breadcrumbs /> */}
            <ModuleHeader />
            {children}
          </Space>
        </Content>
        <Layout.Footer>
          All rights reserved ©{new Date().getFullYear()} | Created By{" "}
          <a rel="noopener noreferrer" target={"_blank"} href={"https://n-idea.net"}>
            N IDEA
          </a>
        </Layout.Footer>
      </Layout>
    </Layout>
  );
};
